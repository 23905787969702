import React from 'react'

const Social = () => {
  return (
    <>

    <div className="has-text-centered" style={{position:'fixed',top:'80px',left:'7px'}}>
    <a rel="noreferrer" href='https://facebook.com/artopenstudioreklamy/' target="_blank">
      <img width="25px" className='icon-green' alt="facebook" src='/img/facebook-square.svg'/>
    </a>
    <br></br>
    <a rel="noreferrer" href='https://www.youtube.com/channel/UCR45VIn9iEmnQo7JU_FL2uQ/' target="_blank">
      <img width="25px" className='icon-green' alt="youtube" src='/img/youtube-square.svg'/>
    </a>
    <br></br>
    <a rel="noreferrer" href='https://www.linkedin.com/company/art-open-studio-reklamy/' target="_blank">
      <img width="25px" className='icon-green' alt="linkedin" src='/img/linkedin.svg'/>
    </a>
    <br></br>
    <a rel="noreferrer" href='https://twitter.com/AReklamy/' target="_blank">
      <img width="25px" className='icon-green' alt="tweeter" src='/img/twitter-square.svg'/>
    </a>
    <br></br>
    <a rel="noreferrer" href='https://www.instagram.com/artopen.pl/' target="_blank">
      <img width="25px" className='icon-green' alt="instagram" src='/img/instagram-square.svg'/>
    </a>
    <br></br>
    <a rel="noreferrer" href='https://www.behance.net/ArtOpenStudioReklamy/' target="_blank">
      <img width="25px" className='icon-green' alt="behance" src='/img/behance-square.svg'/>
    </a>
    </div>

    </>
  )
}

export default Social
