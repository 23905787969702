import React from "react";

const SingleComment = ({ comment }) => {
  const dateString = (commentDate) => {
    var date = new Date(commentDate);
    return (
      date.toLocaleString("pl-PL", { hour: "2-digit" }) +
      ":" +
      date.toLocaleString("pl-PL", { minute: "2-digit" }) +
      "  " +
      date.toLocaleDateString("pl-PL", { day: "2-digit" }) +
      "." +
      date.toLocaleDateString("pl-PL", { month: "2-digit" }) +
      "." +
      date.getFullYear()
    );
  };

  return (
    <div className="single-comment">
      <div className="comment-info" style={{display:'flex',flexWrap:'wrap'}}>
        <div className="user-image">
          <img style={{border:'5px solid #23C495',borderRadius:'5px',marginRight:'20px'}} src={comment.photoUrl} alt="avatar" />
        </div>
        <div className="comment-data">
          <div className="comment-author"><b><p>{comment.name}</p></b></div>
          <div className="comment-time"><p>{dateString(comment.date)}</p></div>
          <div className="comment-text"><p>{comment.comment}</p></div>
        </div>
      </div>

    </div>
  );
};

export default SingleComment;
