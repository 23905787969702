import React from 'react'
import Content from '../Content'
import { kebabCase } from 'lodash'
import { Link } from 'gatsby'
import ProgressiveImageContainer from '../ProgressiveImageContainer'

const ArticleTemplate = (props) => {

  const { content, headings, contentComponent, cover, tags, title, subtitle, date, templateKey, time, sentences, paragraphs, words } = props

  const PostContent = contentComponent || Content
  // console.log(headings);
  return (
    <>

    <div>
      <h1 className='title is-size-2 has-text-weight-bold is-bold-light'
      >
        &bull; {title}
        <br />
        <sub style={{fontSize:'.6em',fontWeight:'normal'}}>Reading time: <b>{time} m</b> </sub>
      </h1>
      <h2 className='title is-size-3 has-text-weight-bold is-bold-light stays-green'
      >
        {subtitle}
      </h2>
      <small className='categ' style={{marginLeft:'0px', color:'#ffffff'}}>{date}</small>
      <br></br>
      <br></br>
      <figure>
      <img
        src={cover}
        alt={title}
        title={title}
        className="imgcont"
      />
      <figcaption>{title}</figcaption>
      </figure>
      <br></br>
      <br></br>
      {headings}
      <PostContent content={content} />
      <div style={{textAlign:'center',marginTop:'30px'}}>
      <br />
      <p  className="subtitle" style={{fontSize:'13px'}}> Number of paragraphs: <b>{paragraphs}</b> &bull; Number of sentences: <b>{sentences}</b> &bull; Number of words: <b>{words}</b></p>
      </div>
      <div style={{ marginTop: `4rem` }}>
        <p>Tags:</p>
        <ul className='taglist'>
          {(tags && tags.length)
            ? tags.map(tag => (
              <li key={tag + `tag`}>
                <Link className="button-green" to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
              </li>
            ))
            : null}
        </ul>
      </div>

    </div>
    </>
  )
}

export default ArticleTemplate
