import React from "react";

const CommentInput = ({ userName, userImage, addComment }) => {
  const [comment, setComment] = React.useState("");

  const handleComment = (event) => {
    setComment(event.target.value);
  };

  const handleSumbitComment = (event) => {
    event.preventDefault();
    var commentDate = Date.now();
    addComment(comment, commentDate);
    setComment("");
  };

  function hideBox(){
    document.getElementsByClassName('input-comment')[0].style.display = 'none';
  }

  return (
    <div className="input-comment" style={{marginBottom:'30px'}}>
      <form onSubmit={handleSumbitComment}>
        <div style={{display:'flex'}} className="comment-info">
          <div className="user-image">
            <img style={{border:'3px solid #23C495',borderRadius:'3px'}} width="50px" src={userImage} alt="avatar" />
          </div>
          <div className="comment-data">
            <div style={{marginLeft:'10px'}} className="comment-author"><b>{userName}</b></div>
          </div>
        </div>
        <div className="comment-text">
          <textarea
            className="textarea"
            value={comment}
            onChange={handleComment}
            rows={5}
            cols={75}
            placeholder="Write what you think!"
          />
          <div className="input-comment-button">
          <br />
            <input className="button is-primary" type="submit" value="Dodaj" />
          </div>
        </div>
      </form>
    </div>
  );
};

export default CommentInput;
