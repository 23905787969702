import React from 'react'
import { TrimStr } from '../Trim'

const ToC = ({ headings }) => (
  <>
    <p className="title is-6">Spis treści:</p>
    <div>
      {headings.map(heading => {
        if (heading.depth > 5) {
          return <div />
        }

        return (
          <li key={heading.value}>
            <a className="inline-link"
              href={`#${TrimStr(heading.value)}`}
            >
              {heading.value}
            </a>
          </li>
        )
      })}
      <br />
    </div>
  </>
)

export default ToC
